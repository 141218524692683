/* Create two equal columns that floats next to each other */
.flex-column {
    width: 50%;
    padding: 0 10px;
}

/* Clear floats after the columns */
.flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
}

.page {
    max-width: 800px;
    margin: 0 auto;
}

@media screen and (max-width: 600px) {
    .flex-column {
        width: 100%;
        margin: 0 10px;
    }
}