@import url('https://fonts.googleapis.com/css2?family=PT+Sans&family=Source+Sans+Pro:wght@200&family=Space+Mono&display=swap');


:root {
    --primary: #7ad3e9;
    --secondary: #f5814c;
    --background-primary: #201f1f;
    --background-secondary: #585555;
    --link-primary: #db44c7;
    --link-hover: #ac3b9d;
}

html, body {
    background-color: var(--background-primary);
    font-family: 'Source Sans', sans-serif;
    font-size: 19px;
}

input, textarea, label, .input-group-text {
    background: var(--background-primary) !important;
    color: #eee !important;
    border-radius: 0 !important;
    border: 2px solid var(--secondary) !important;
}

.input-group-text {
    color: gray !important;
    border: 2px solid gray !important;
}

p, li {
    color: #ddd;
} 

h1, h2, h3, h4, h5 {
    font-family: 'Space Mono', monospace;
    color: var(--secondary);
    margin-bottom: 1em;
}

a {
    font-family: 'Space Mono', monospace;
    color: var(--link-primary);
    text-decoration: none;
}

a:hover {
    color: var(--link-hover);
}

h1, h2, h3 {
    color: var(--primary);
    
}


ol > li {
    margin-bottom: 1.5rem;
    margin-left: 0;
}

button {
    color: var(--link-primary) !important;
    border: 2px solid  var(--link-primary) !important;
    background-color: var(--background-primary) !important;
    border-radius: 0 !important;
}

button:hover {
    color: white !important;
    background-color: var(--link-primary) !important;
}

.nav-link {
    padding: 0 1em;
}

.nav-link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 992px) {
    h1 {
        font-size: 32px;
    }
}