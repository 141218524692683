.flex-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}




.flex-col {
    padding: 10px;
    min-height: 120px;
    width: 16rem;
    margin: 10px;
    border: 4px solid var(--primary);
}



.orange {
    border-color: var(--secondary);
}


.flex-col:hover {
    border-color: white;
}