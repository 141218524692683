@import url(https://fonts.googleapis.com/css2?family=PT+Sans&family=Source+Sans+Pro:wght@200&family=Space+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --primary: #7ad3e9;
    --secondary: #f5814c;
    --background-primary: #201f1f;
    --background-secondary: #585555;
    --link-primary: #db44c7;
    --link-hover: #ac3b9d;
}

html, body {
    background-color: #201f1f;
    background-color: var(--background-primary);
    font-family: 'Source Sans', sans-serif;
    font-size: 19px;
}

input, textarea, label, .input-group-text {
    background: #201f1f !important;
    background: var(--background-primary) !important;
    color: #eee !important;
    border-radius: 0 !important;
    border: 2px solid #f5814c !important;
    border: 2px solid var(--secondary) !important;
}

.input-group-text {
    color: gray !important;
    border: 2px solid gray !important;
}

p, li {
    color: #ddd;
} 

h1, h2, h3, h4, h5 {
    font-family: 'Space Mono', monospace;
    color: #f5814c;
    color: var(--secondary);
    margin-bottom: 1em;
}

a {
    font-family: 'Space Mono', monospace;
    color: #db44c7;
    color: var(--link-primary);
    text-decoration: none;
}

a:hover {
    color: #ac3b9d;
    color: var(--link-hover);
}

h1, h2, h3 {
    color: #7ad3e9;
    color: var(--primary);
    
}


ol > li {
    margin-bottom: 1.5rem;
    margin-left: 0;
}

button {
    color: #db44c7 !important;
    color: var(--link-primary) !important;
    border: 2px solid  #db44c7 !important;
    border: 2px solid  var(--link-primary) !important;
    background-color: #201f1f !important;
    background-color: var(--background-primary) !important;
    border-radius: 0 !important;
}

button:hover {
    color: white !important;
    background-color: #db44c7 !important;
    background-color: var(--link-primary) !important;
}

.nav-link {
    padding: 0 1em;
}

.nav-link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 992px) {
    h1 {
        font-size: 32px;
    }
}
.flex-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}




.flex-col {
    padding: 10px;
    min-height: 120px;
    width: 16rem;
    margin: 10px;
    border: 4px solid var(--primary);
}



.orange {
    border-color: var(--secondary);
}


.flex-col:hover {
    border-color: white;
}
ul.suggestions {
    display: block;
    position: absolute;
    z-index: 1000;
  }

.suggestions {
    border: 2px solid var(--primary);
    list-style: none;
    margin-top: 0;
    background: black;
    width: 100%;
    padding: 0;
}

.suggestions > li {
    padding: 12px 10px;
    cursor: pointer;
    border-bottom: 1px solid var(--background-primary)
}
.tipblock {
    padding: 20px;
    border: 4px solid var(--primary);
    font-family: 'Space Mono', monospace;
    display: inline-block;
    width: 100%;
}

.tipblock p {
    margin: 0;
}

.steps li::after {
    margin-left: 110px;
}

.steps li::marker {
    color: var(--primary);
    font-weight: 600;
}

.fixed-aspectratio {
    width: 50%;
    padding-top: 50%;
    position: relative;
    float: left;
    margin-bottom: 40px;
}



.main-image {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 4px solid var(--secondary);
    
}


.ingredientlist {
    list-style: none;
    padding: 0;
}

.ingredientlist > li {
    border-bottom: 1px solid var(--primary);
    line-height: 2.2;
    padding: 4px 0;
    font-size: 0.9rem;;
}

.input-group-text {
    color: rgb(124, 124, 124);
    background: none;
}
/* Create two equal columns that floats next to each other */
.flex-column {
    width: 50%;
    padding: 0 10px;
}

/* Clear floats after the columns */
.flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
}

.page {
    max-width: 800px;
    margin: 0 auto;
}

@media screen and (max-width: 600px) {
    .flex-column {
        width: 100%;
        margin: 0 10px;
    }
}
