ul.suggestions {
    display: block;
    position: absolute;
    z-index: 1000;
  }

.suggestions {
    border: 2px solid var(--primary);
    list-style: none;
    margin-top: 0;
    background: black;
    width: 100%;
    padding: 0;
}

.suggestions > li {
    padding: 12px 10px;
    cursor: pointer;
    border-bottom: 1px solid var(--background-primary)
}