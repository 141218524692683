.tipblock {
    padding: 20px;
    border: 4px solid var(--primary);
    font-family: 'Space Mono', monospace;
    display: inline-block;
    width: 100%;
}

.tipblock p {
    margin: 0;
}

.steps li::after {
    margin-left: 110px;
}

.steps li::marker {
    color: var(--primary);
    font-weight: 600;
}

.fixed-aspectratio {
    width: 50%;
    padding-top: 50%;
    position: relative;
    float: left;
    margin-bottom: 40px;
}



.main-image {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 4px solid var(--secondary);
    
}


.ingredientlist {
    list-style: none;
    padding: 0;
}

.ingredientlist > li {
    border-bottom: 1px solid var(--primary);
    line-height: 2.2;
    padding: 4px 0;
    font-size: 0.9rem;;
}

.input-group-text {
    color: rgb(124, 124, 124);
    background: none;
}